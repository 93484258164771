import {Box, Typography} from "@mui/material";

export default function DonationComplete() {
  return <Box p={2}>
    <Typography variant="h3" fontFamily="Dancing Script" textAlign="center" mt={2} ml={2}>
      Thank You For Your Support!
    </Typography>
    <br/>
    <Typography textAlign="center">A confirmation email about your donation has been sent to your provided email address. You can safely close this page.</Typography>
  </Box>
}
