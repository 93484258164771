import {Button, Stack, Typography} from '@mui/material';
import Image from './Image';
import {useNavigate} from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return <Stack alignItems="center">
    <Image
      sx={{maxWidth: '100vw', cursor: 'pointer', scale: 0.7}}
      src="logo.png"
      alt="A blue floral-themed logo for Sophia Women's Center"
    />
    <Typography variant="h3" fontFamily="Dancing Script" textAlign="center" mt={2} ml={2}>Welcome!</Typography>
    <Typography ml={2} mr={2}>
      Thank you for supporting Sophia Women's Center. If you would like to stay connected with us by phone, please click the subscribe
      button at the top-right of the page and complete the form. Once completed, you will be able to receive phone message updates from
      us!
    </Typography>
    <Stack direction="row" justifyContent="center" sx={{p: 2}}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate('/support');
        }}
      >
        Donate Online!
      </Button>
    </Stack>
  </Stack>
};

export default Home;
