import {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Skeleton} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';

const Subscribe = ({open, onClose}) => {
  const cancel = useRef(false);
  useEffect(() => {
    cancel.current = false;

    return () => {
      cancel.current = true;
    }
  }, []);

  const [loadingSubscribeForm, setLoadingSubscribeForm] = useState(true);
  const [loadedSubscribeForm, setLoadedSubscribeForm] = useState(false);

  const loadSubscribeForm = useCallback(node => {
    if (node !== null && !loadedSubscribeForm) {
      const script = document.createElement('script');

      script.src = 'https://www.dialmycalls.com/widget/';
      script.async = true;
      script.onload = () => {
        if (!cancel.current) {
          setLoadedSubscribeForm(true);
        }

        // Waits a second, so that the subscribe form renders before we hide the Skeleton.
        setTimeout(() => {
          if (!cancel.current) {
            setLoadingSubscribeForm(false);
          }
        }, 400);
      };
      script.onerror = () => {
        console.error('Failed to load donation form.');
        if (!cancel.current) {
          setLoadingSubscribeForm(false);
        }
      };

      node.appendChild(script);
    }

    return node;
  }, [loadedSubscribeForm]);

  useEffect(() => {
    // Since the subscriber form is rendered outside of React, we have to reload it each time the Dialog is opened/closed.
    if (open) {
      setLoadingSubscribeForm(true);
      setLoadedSubscribeForm(false);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loadingSubscribeForm && <Skeleton variant="rectangular" height="450px"/>}
        <Box sx={{display: loadingSubscribeForm ? 'none' : 'unset'}}>
          <Box ref={loadSubscribeForm} data-dmc-widget data-dmc-widget-id="byb"/>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Subscribe;
